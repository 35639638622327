import { getBaseApiHelper } from '../../common/utils/apiHelpers'


export const CONSTANTS_REQUEST = 'CONSTANTS_REQUEST'
export const CONSTANTS_SUCCESS = 'CONSTANTS_SUCCESS'
export const CONSTANTS_FAILURE = 'CONSTANTS_FAILURE'


// CONSTANTS API INCLUDES CROPS AND INDICATORS
// Constants dispatched actions triggers crops and indicator reducers
function constantsRequest() {
  return {
    type: CONSTANTS_REQUEST,
    isFetching: true,
  }
}

function constantsSuccess(constants){
  return {
    type: CONSTANTS_SUCCESS,
    constants: constants,
    shis: constants.shis,
    cropPathogens: constants.crop_pathogens,
    chemistryParameters: constants.chemistry_parameters
  }
}

function constantsFailure(error) {
  return {
    type: CONSTANTS_FAILURE,
    error,
  }
}
// Thunks/Async Action Creators
export function getConstants() {
  return dispatch => {
    dispatch(constantsRequest())
    return getBaseApiHelper('/constants/v2')
      .then(response => dispatch(constantsSuccess(response.data)))
      .catch(rejected => dispatch(constantsFailure(rejected)))
  }
}
